import React, { FC } from 'react';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { gaButtonEvent } from './../../services/visitor-info';
import { ButtonColor, ButtonStyle } from '../../models/button-style';
import { Route } from '../../models/site-links';
import * as downArrowWhite from '../../images/down-arrow-white.svg';
import * as downArrowBlack from '../../images/down-arrow-black.svg';
import './cta-buttons.css';

interface buttonProps {
  buttonId: string;
  text: string;
  buttonColor: ButtonColor;
  buttonStyle: ButtonStyle;
  aniLink?: boolean;
  link?: Route;
  anchor?: string;
  justify?: string;
  ggBlue?: boolean;
  type?: any;
}

const Button: FC<buttonProps> = (props) => {
  if (props.aniLink) {
    return (
      <AniLink fade to={props.link ? props.link : ''}>
        <button
          onClick={() => gaButtonEvent(props.text, props.buttonId)}
          type={props.type ? props.type : 'button'}
          aria-label={props.text}
          className={` ${props.buttonStyle} ${props.buttonColor}`}
        >
          {props.text}
        </button>
      </AniLink>
    );
  }
  if (props.anchor) {
    return (
      <a
        target="_blank"
        className={`w-full flex text-center align-center ${
          props.justify ? props.justify : 'justify-center'
        }`}
        href={props.link ? props.link : '#'}
      >
        <AnchorLink to={props.anchor} className={`${props.buttonStyle} ${props.buttonColor}`}>
          <div className="flex flex-row min-w-full items-center">
            <span className="items-end">{props.text}</span>
            {props.buttonColor === ButtonColor.HeroAnchorLight && (
              <img className="h-6 w-6" src={downArrowWhite}></img>
            )}
            {props.buttonColor === ButtonColor.HeroAnchorDark && (
              <img className="h-6 w-6" src={downArrowBlack}></img>
            )}
          </div>
        </AnchorLink>
      </a>
    );
  }

  return (
    <a
      target="_blank"
      className={`w-full flex text-center align-center ${
        props.justify ? props.justify : 'justify-center'
      }`}
      href={props.link ? props.link : '#'}
    >
      <button
        onClick={() => gaButtonEvent(props.text, props.buttonId)}
        type={props.type ? props.type : 'button'}
        aria-label={props.text}
        className={` ${props.buttonStyle} ${props.buttonColor}`}
      >
        {props.text}
      </button>
    </a>
  );
};
export default Button;
