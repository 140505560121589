export enum ButtonColor {
  Primary = 'text-shades-0 bg-primary hover:bg-primary-400 border-2 border-primary',
  Secondary = 'text-secondary border-2 border-secondary hover:bg-primary-300',
  HeroAnchorLight = 'text-shades-0',
  HeroAnchorDark = 'text-shades-100 hover:text-neutral-500',
  FormSubmission = 'text-shades-0 bg-primary hover:bg-primary-400',
  OpaqueBlack = 'text-neutral-700 bg-shades-0 hover:bg-white',
  OpaqueSecondary = '',
  GradientBlue = '',
  BlackCancelPrimary = 'text-shades-0 bg-shades-100 hover:bg-neutral-800 border-2 border-neutral-800',
  CancelBlackSecondary = 'text-black bg-white hover:bg-neutral-800 border-2 border-grey-neutral-800',
  CancelSubscription = 'text-error-700 bg-white hover:bg-neutral-50 border-2 border-error-700',
  IVAC = 'text-primary bg-white hover:bg-primary-400 border-2 border-primary',
}

export enum ButtonStyle {
  FatRounded = 'text-sm py-2.5 px-6 my-4 cursor-pointer rounded',
  HeroButton = 'text-md font-medium py-2.5 px-10 tab:px-16 my-4 cursor-pointer rounded-lg',
  Feature = 'text-sm font-medium py-4 px-8 my-4 cursor-pointer rounded-lg',
  FeatureLong = 'text-sm w-44 py-2.5 my-4 cursor-pointer rounded-lg',
  FormSubmission = 'flex justify-center text-base w-full rounded-lg text-md pt-4 pb-4 cursor-pointer',
  HeroStacked = 'text-md font-medium py-2.5 px-10 tab:px-16 my-2 cursor-pointer rounded-lg',
  HeroAnchor = 'font-body mb-16 lap:mb-0 text-lg mt-4 text-decoration-line: underline cursor-pointer',
  CancelButton = 'text-md font-medium py-2.5 px-10 tab:px-24 my-4 cursor-pointer rounded-lg',
  CancelOffer = 'flex truncate justify text-md font-medium py-2.5 px-10 tab:px-20 my-4 cursor-pointer rounded-lg',
  RetentionButtonMed = 'text-lg w-228px py-2.5 my-4 cursor-pointer rounded-lg',
  IVAC = 'text-sb w-130px h-48px px py-2.5 my-4 cursor-pointer rounded-lg',
}
